import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import Helmet from "react-helmet"
import loadable from '@loadable/component'

import Container from "../components/layout/Container"
import SEO from "../components/seo"
import HeroHeader from "../components/layout/HeroHeader"

import WhyWidget from "../components/widgets/WhyWidget"
import ProductsWidget from "../components/widgets/ProductsWidget"
import SignPhotoCarousel from "../components/SignPhotoCarousel"

import { colors, devices, storage } from "../settings"

const MapWidget = loadable(() => import('../components/widgets/MapWidget'));
const DesignBuildInstallSection = loadable(() => import('../components/sections/DesignBuildInstallSection'));
const BlogSection = loadable(() => import('../components/sections/BlogSection'));
const DifferentSection = loadable(() => import('../components/sections/DifferentSection'));

const Menu = styled.ul`
  display: none;
  list-style-type: none;
  padding: 0px;
  margin: 0px 0px 20px 0px;
  background-color: white;

  @media ${devices.mobile} {
    display: block;
  }

  a {
    padding: 16px 20px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    border-bottom: 1px solid #ccc;
    color: black;

    svg {
      color: #666;
    }
  }

  li.design {
    background-color: ${colors.red};

    a {
      color: white;
      box-shadow: 0px 5px 5px #ccc;

      svg {
        color: white;
      }
    }
  }
`

const Content2 = styled.div`
  border-top: 3px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-image: url(${storage.url}2020/tiles/grey3.jpg);
  padding-top: 25px;
  margin-top: 30px;

  @media ${devices.mobile} {
    margin-top: 0px;
  }  
`
const Content3 = styled.div`
  background-image: url(${storage.url}2020/tiles/grey4.jpg);
  padding-top: 25px;
  padding-bottom: 25px;

  @media ${devices.mobile} {
    padding-top: 0px;
  }  
`

const IndexPage = ({data}) => {

  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Sign Monkey",
    "url": "https://signmonkey.com",
    "logo": "https://storage.googleapis.com/signmonkey-148101.appspot.com/images/logo-monkey.png",
    "image": "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/carousel/channel-letters-200.webp?v20"
  });

  return (
    <>
      <SEO page="home" title="Store Front Channel Letters & Lit Shape Signs" />

      <Helmet>
        <script type="application/ld+json">{structuredData}</script> 
      </Helmet>

      <HeroHeader 
        heroSrc="2020/hero/building7.jpg"
        heroMobileSrc="2020/hero/building-phone.jpg"
        // heroSrc="2020/hero/building-4thjulysale-2024.jpg"
        // heroMobileSrc="2020/hero/building-4thjulysale-2024-phone.jpg"        
        personSrc="2020/hero/person3.png"
      />
      <Menu>
        <li className="design">
          <Link to="/builder/?from=HomePageMobileMenu">
            Design &amp; Price Sign Now
            <FontAwesomeSvgIcon icon={faChevronRight} />
          </Link>
        </li>
        <li>
          <Link to="/products/">
            Products
            <FontAwesomeSvgIcon icon={faChevronRight} />
          </Link>
        </li>
      </Menu>

      <Container>
        <WhyWidget />
      </Container>

      <SignPhotoCarousel />

      <br />
      
      <ProductsWidget />

      <Container width="800">
        <MapWidget />
      </Container>

      <Content2>
        <Container width="800">
          <DesignBuildInstallSection />
        </Container>
      </Content2>

      <Container width="800">
        <BlogSection />
      </Container>

      <Content3>
        <Container>
          <DifferentSection />
        </Container>
      </Content3>
    </>
  )
}

export default IndexPage
